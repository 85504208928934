import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BulletText from 'ui-kit/bullet-text/BulletText';
import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import './EmptyPrescriptionList.style.scss';

export interface EmptyPrescriptionListProps {
    bodyLineOne: string;
    bodyLineTwo: string;
    linkCtas: ButtonProps[];
}

export default function EmptyPrescriptionList({ bodyLineOne, bodyLineTwo, linkCtas }: EmptyPrescriptionListProps) {
    return (
        <Container fluid className="empty-prescription-list pl-0">
            <Row>
                <Col sm={3} md={10} lg={8}>
                    <BulletText
                        className="empty-prescription-list__text h3"
                        text={
                            <>
                                {bodyLineOne}
                                <br />
                                {bodyLineTwo}
                            </>
                        }
                    />
                </Col>
            </Row>
            <Row className="d-flex flex-column align-items-start ml-2 mt-3">
                <Col xs={12}>
                    {linkCtas.map((linkCta, idx) => (
                        <div key={`empty-prescription-list-link-${idx}`}>
                            <Button
                                label={linkCta.label}
                                type={linkCta.type}
                                onClick={linkCta.onClick}
                                variant={'text-blue'}
                                dataGAFormName={linkCta.dataGAFormName}
                                chevron="right"
                            />
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
