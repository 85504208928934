import { TFunction } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { PrescriptionCardProps, StatusProps } from './types';
import { CURRENT_STEP_DESCRIPTION, isRxOnHold, isShipStatusExpired } from 'util/prescription';

function getSortOrderForRxSubStatusStep(currentStep?: number): number {
    switch (currentStep) {
        // case CURRENT_STEP_DESCRIPTION.UNKNOWN: // (0) pending - do nothing here, let default below be returned
        case CURRENT_STEP_DESCRIPTION.PROCESSING:
            return 42;
        case CURRENT_STEP_DESCRIPTION.FILLING_IN_PHARMACY:
            return 44;
        case CURRENT_STEP_DESCRIPTION.PACKAGED:
            return 46;
        case CURRENT_STEP_DESCRIPTION.SHIPPED:
            return 52;
        case CURRENT_STEP_DESCRIPTION.OUT_FOR_DELIVERY:
            return 55;
        case CURRENT_STEP_DESCRIPTION.DELIVERED:
            return 58;
    }

    // unknown currentStep or step 0 (unknown/pending)
    return 40;
}

export function prescriptionPayloadToProps(
    payload: PrescriptionObjectPayload,
    t: TFunction<'translation'>,
    accountHasInsurance: boolean,
    onOrderRxClick?: () => void
): PrescriptionCardProps {
    onOrderRxClick = onOrderRxClick ? onOrderRxClick : () => {};
    let orderStatus: PrescriptionCardProps['orderStatus'] = 'NO_REFILLS';
    let sortOrder = 60;

    if (payload.inOrderCart) {
        orderStatus = 'IN_CART';
        sortOrder = 10;
    } else {
        if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus === 'Profiled') {
            orderStatus = 'NEW_RX';
            sortOrder = 20;
        }
        if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus !== 'Profiled') {
            orderStatus = 'REFILL_AVAILABLE';
            sortOrder = 30;
        }
        if (payload.webEligibilityStatus === 'NOT_ELIGIBLE' && payload.itemInWorkflow === true) {
            orderStatus = 'ORDERED';
            sortOrder = getSortOrderForRxSubStatusStep(payload.rxSubStatus?.CurrentStep);
        }
        if (
            payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
            payload.orderLineQueueStatus === 'SHIPPED' &&
            payload.itemInWorkflow === false
        ) {
            orderStatus = isShipStatusExpired(payload.lastFillDate) ? 'REFILL_TOO_SOON' : 'SHIPPED';
            sortOrder = isShipStatusExpired(payload.lastFillDate)
                ? 70
                : getSortOrderForRxSubStatusStep(payload.rxSubStatus?.CurrentStep);
        }
        if (
            payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
            payload.nextFillDate !== undefined &&
            payload.itemInWorkflow === false
        ) {
            orderStatus = isShipStatusExpired(payload.lastFillDate) ? 'REFILL_TOO_SOON' : 'SHIPPED';
            sortOrder = isShipStatusExpired(payload.lastFillDate)
                ? 70
                : getSortOrderForRxSubStatusStep(payload.rxSubStatus?.CurrentStep);
        }
        if (isRxOnHold(payload)) {
            orderStatus = 'ON_HOLD';
            sortOrder = 80;
        }
        if (!accountHasInsurance && !payload.inFormulary) {
            orderStatus = 'NOT_ON_FORMULARY';
            sortOrder = 90;
        }
        if (payload.rxSubStatus?.CurrentStep === CURRENT_STEP_DESCRIPTION.UNKNOWN) {
            orderStatus = 'PENDING_ORDERED';
            sortOrder = 90;
        }
        if (payload.webEligibilityStatus === 'PENDING') {
            orderStatus = 'PENDING';
            // show new rx requests before transfer rx requests
            sortOrder = payload.rxNumber && payload.rxNumber !== 'N/A' ? 105 : 100;
        }
        if (payload.webEligibilityStatus === 'MDO_CANCELLED') {
            orderStatus = 'MDO_CANCELLED';
            sortOrder = 110;
        }
        if (payload.webEligibilityStatus === 'MDO_WAITING') {
            orderStatus = 'MDO_WAITING';
            sortOrder = 120;
        }
    }

    // push next fill date for any RX other than New, Pending or Expired
    const showNextFillDate = (props: any) => {
        if (payload.nextFillDate) {
            const nextFillDateFormatted = moment(payload.nextFillDate).format('MM/DD/YYYY');
            props.statuses.push({
                primary: false,
                status: <div>{nextFillDateFormatted}</div>,
                displayType: 'NEXT_REFILL'
            });
        }
    };

    const props = {
        fullPayload: payload, // for debugging
        prescriptionName: payload.dispensedProductName,
        orderStatus: orderStatus,
        sortOrder: sortOrder,
        inOrderCart: payload.inOrderCart,
        refillsLeft: Number(payload.fillsRemaining),
        rxNumber: payload.rxNumber,
        rxSeqNum: payload.rxSeqNum,
        details: [
            {
                detail: t('components.prescriptionCard.quantity', {
                    fillQuantity: payload.fillQuantity
                })
            },
            {
                detail: payload.dispensedProductStrength
                    ? payload.dispensedProductStrength +
                      (payload.dispensedProductUnitOfMeasure ? payload.dispensedProductUnitOfMeasure.toLowerCase() : '')
                    : null,
                primary: true
            },
            {
                detail: payload.dispensedProductDosageForm ? payload.dispensedProductDosageForm.toLowerCase() : null
            }
        ],
        statuses: [] as StatusProps[],
        ctas: [
            {
                label:
                    orderStatus !== 'IN_CART'
                        ? t('components.prescriptionCard.orderPrescription')
                        : t('components.prescriptionCard.inYourCart'),
                onClick: onOrderRxClick,
                disabled:
                    orderStatus !== 'NEW_RX' && orderStatus !== 'REFILL_AVAILABLE' && orderStatus !== 'NO_REFILLS',
                isMobile: false
            }
        ],
        footNote: undefined
    };
    if (orderStatus === 'IN_CART') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.inYourCart')}</div>,
            displayType: 'RX'
        });
    }
    if (orderStatus === 'NEW_RX') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderNewRxArrived')}</div>,
            displayType: 'RX'
        });
    }
    if (orderStatus === 'ORDERED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
            displayType: 'RX'
        });
        props.statuses.push({
            primary: false,
            status: <div>{t('components.prescriptionCard.orderInProgress')}</div>,
            displayType: 'ORDER'
        });
    } else if (orderStatus === 'SHIPPED') {
        const orderStatus = !payload.lastFillDate
            ? t('components.prescriptionCard.orderProcessingText')
            : t('components.prescriptionCard.orderShippedText');

        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
            displayType: 'RX'
        });
        props.statuses.push({
            primary: false,
            status: <div>{orderStatus}</div>,
            displayType: 'ORDER'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'REFILL_TOO_SOON') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRefillTooSoon')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'REFILL_AVAILABLE') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRefillAva')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'NO_REFILLS') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderExpired')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.orderPreAuthReq');
    } else if (orderStatus === 'ON_HOLD') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.rxOnHold')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.rxOnHoldFootnote');
    } else if (orderStatus === 'NOT_ON_FORMULARY') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.notOnFormulary')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
        props.footNote = t('components.prescriptionCard.notOnFormularyMessage');
    } else if (orderStatus === 'PENDING_ORDERED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.unknownStatusMessage');
    } else if (orderStatus === 'PENDING') {
        props.statuses.push({
            primary: true,
            status: (
                <div>
                    {payload.rxNumber && payload.rxNumber !== 'N/A'
                        ? t('components.prescriptionCard.transferPending')
                        : t('components.prescriptionCard.requestPending')}
                </div>
            ),
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.pendingMessage');
    } else if (orderStatus === 'MDO_CANCELLED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderMdoCancelled')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.orderMdoCancelledMessage');
    } else if (orderStatus === 'MDO_WAITING') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderMdoWaiting')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.orderMdoWaitingMessage');
    }

    return props;
}
