import React, { useCallback, useEffect, useState } from 'react';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';

import { formatPrice } from 'schema/price.schema';

import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetGetStatusForRx,
    medicineCabinetToggleAutoRefillAllRxs
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import { startCartRoutine, updateRefillLinesRoutine } from 'state/cart/cart.routines';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { cartItemsSelector } from 'state/cart/cart.selectors';
import {
    medicineCabinetAutoRefillPlanEligibleSelector,
    medicineCabinetAutoRefillRxsSelector,
    medicineCabinetFilteredRxsForStatusSelector,
    medicineCabinetPopulatedSelector,
    medicineCabinetPrescriptionsSelector,
    medicineShowNewPrescriptionModalSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { closeNewPrescriptionModal } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import { accountFetchPlansRoutine, accountGetAllCreditCardsRoutine } from 'state/account/account.routines';

import PrescriptionList from 'components/prescriptions-list/PrescriptionList';
import PrescriptionCard from 'components/prescriptions-list/PrescriptionCard/PrescriptionCard';
import { prescriptionPayloadToProps } from 'components/prescriptions-list/PrescriptionCard/payloadToProps';
import MedicineCabinetLayout from 'components/layouts/medicine-cabinet/medicine-cabinet.layout';
import DashboardCtaBlock from 'components/dashboard-cta-block/DashboardCtaBlock';
import BirdiModalContent, { OverflowModal } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import PageSection from 'ui-kit/page-section/page-section';
import {
    accountProfileSelector,
    accountHasInsuranceSelector,
    accountCreditCardsSelector,
    accountPlansSelector
} from 'state/account/account.selectors';
import { hasAdjudicatedPrice, processCart } from 'util/cart';

// analytics
import { TrackCheckoutStep, TrackNewPrescriptionNumber } from 'util/google_optimize/optimize_helper';
import { drugSelector } from 'state/drug/drug.selectors';
import AccountBalance from 'components/account-balance/account-balance.component';
import { ToggleAutoFillRequest } from 'state/medicine-cabinet/medicine-cabinet.services';
import { AutoRefillTermsAndConditionsModal } from 'components/auto-refill-terms-and-conditions-modal';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

const MedicineCabinet = ({ data }: { data: GatsbyTypes.MedicineCabinetDataQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cartItemsObject = useSelector(cartItemsSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const prescriptions = useSelector(medicineCabinetPrescriptionsSelector);
    const newPrescriptionModal = useSelector(medicineShowNewPrescriptionModalSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();
    const profileObject = useSelector(accountProfileSelector);
    const [isAddingToCart, setIsAddingToCart] = useState(true);
    const pillImage = data.successModalPillImage;
    const [rxsLoaded, setRxsLoaded] = useState(false);
    const accountPlans = useSelector(accountPlansSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const displayShowToggleAutoRefillAll = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);
    const autoRefillEligibleRxs = useSelector(medicineCabinetAutoRefillRxsSelector);
    const medicineCabinetPopulated = useSelector(medicineCabinetPopulatedSelector);
    const medicineCabinetFilteredRxsForStatus = useSelector(medicineCabinetFilteredRxsForStatusSelector);

    useEffect(() => {
        const newPrescriptionsModalContent = (prescriptions: string[]) => {
            const isMultiple = prescriptions.length > 1;
            return (
                <BirdiModalContent
                    icon={'default'}
                    title={
                        isMultiple
                            ? t('modals.newPrescriptions.title.multiplePrescriptions')
                            : t('modals.newPrescriptions.title.singlePrescription')
                    }
                    body={
                        <div className="text-left lead">
                            <p>
                                {isMultiple
                                    ? t('modals.newPrescriptions.body.multiplePrescriptions')
                                    : t('modals.newPrescriptions.body.singlePrescription')}
                            </p>
                            <ul className="font-weight-bold pl-3">
                                {prescriptions.map((rx, index) => (
                                    <li key={index}>{rx}</li>
                                ))}
                            </ul>
                            <p>{t('modals.newPrescriptions.body.orderNow')}</p>
                        </div>
                    }
                />
            );
        };
        if (newPrescriptionModal.show) {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: newPrescriptionsModalContent(newPrescriptionModal.prescriptions),
                    onClose: () => {
                        dispatch(closeNewPrescriptionModal());
                    },
                    ctas: [
                        {
                            label: t('modals.newPrescriptions.labels.viewMedicineCabinet'),
                            variant: 'primary',
                            onClick: (event) => {
                                dispatch(closeModal({}));
                                dispatch(closeNewPrescriptionModal());
                                navigate('/secure/medicine-cabinet');

                                const eventTarget = event?.target as HTMLElement;
                                if (newPrescriptionModal.prescriptions.length > 0 && eventTarget) {
                                    TrackNewPrescriptionNumber(eventTarget, newPrescriptionModal.prescriptions.length);
                                }
                            },
                            dataGALocation: t('modals.newPrescriptions.title.singlePrescription').replace(/ /g, '')
                        }
                    ]
                })
            );
        }
    }, [newPrescriptionModal, dispatch, t]);

    useEffect(() => {
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true, onSuccess: () => setRxsLoaded(true) }));
        if (!allPaymentData) {
            dispatch(accountGetAllCreditCardsRoutine.trigger()); // needed to init Cart and New/Transfer workflows
        }
        if (!accountPlans || accountPlans.length === 0) {
            dispatch(accountFetchPlansRoutine());
        }
        setIsAddingToCart(false);
    }, []);

    useEffect(() => {
        // Get discount drug prices after the profile and all prescriptions are
        // loaded.
        if (profileObject !== undefined && prescriptions.length > 0) {
            let birdiPricePrescriptions: any[] = [];
            // DRX-583 - get prices for Insured users to pre-fetch for cart. We only need to get
            // prices for items in cart.
            if (accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE) {
                // DRX-996 - Since we have to show a different message when an item is added to the cart,
                // we need to get all Rxs that are in the cart, or could be added to the cart.
                birdiPricePrescriptions = prescriptions.filter(function (rx) {
                    return (
                        rx.inOrderCart === true ||
                        rx.webEligibilityStatus === 'ELIGIBLE' ||
                        rx.webEligibilityStatus === 'AUTH_REQ'
                    );
                });
            } else {
                // No need to get "pending" prescriptions for Birdi price users
                if (!accountHasInsurance) {
                    birdiPricePrescriptions = prescriptions.filter(function (rx) {
                        return !!(rx.dispensedProductNumber || rx.writtenProductNumber);
                    });
                }
            }

            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: birdiPricePrescriptions,
                    forceBirdiInsurance: !!(accountHasInsurance && ALLOW_INSURED_BIRDI_PRICE),
                    location: 'MedCabinet'
                })
            );
        }
    }, [profileObject?.epostPatientNum, prescriptions.length]);

    const handleAddToCartClick = (rxNumber: string) => {
        setIsAddingToCart(true);
        const currentPrescription = prescriptions.find((obj: any) => {
            return obj.rxNumber === rxNumber;
        });
        let cartRoutineSwitch;
        let rxInCart = false;
        if (!cartItemsObject || cartItemsObject?.length === 0) {
            cartRoutineSwitch = startCartRoutine;
        } else {
            cartRoutineSwitch = updateRefillLinesRoutine;
            // Make sure item isn't already in cart, due to super-fast clicking. TODO: move to saga
            //cast alreadyInCart = orderObject.refillRxs.find(action.payload.rxNumber)
            if (cartItemsObject) {
                rxInCart = cartItemsObject.some((cartItem) => cartItem.rxNumber === rxNumber);
            }
        }

        if (!rxInCart) {
            dispatch(
                cartRoutineSwitch.trigger({
                    rxNumbers: [rxNumber],
                    onFailure: (data: any) => {
                        setIsAddingToCart(false);
                        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.medicineCabinet.messages.callbacks.error`)}
                                        body={t(`pages.medicineCabinet.messages.callbacks.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    },
                    onSuccess: (data: any) => {
                        const itemCount = data.refillRxs.length;
                        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));

                        const extendedCart = processCart(
                            data,
                            accountHasInsurance,
                            prescriptions,
                            drugDiscountPrices,
                            accountPlans
                        );
                        const refillRxItem = extendedCart.extendedRefillRxs?.find((obj: any) => {
                            return obj.rxNumber === rxNumber;
                        });
                        TrackCheckoutStep({
                            stepName: 'add',
                            step: '1',
                            cart: extendedCart,
                            prescriptions: prescriptions,
                            t: t,
                            shippingCost: '0',
                            accountHasInsurance: accountHasInsurance
                        });
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                contentClassName: 'overflow-modal',
                                bodyContent: (
                                    <OverflowModal
                                        title={t('modals.updateCart.addedToCart')}
                                        text={t('modals.updateCart.addedContentsMessage', {
                                            drug: currentPrescription?.dispensedProductName,
                                            price: !hasAdjudicatedPrice(refillRxItem, currentPrescription)
                                                ? ''
                                                : `(${formatPrice(refillRxItem.patientCopay)})`,
                                            itemCount: `<strong>${itemCount}</strong>`,
                                            count: itemCount
                                        })}
                                        warningText={
                                            !hasAdjudicatedPrice(refillRxItem, currentPrescription)
                                                ? t(
                                                      refillRxItem?.showDisclaimer
                                                          ? refillRxItem.disclaimerTranslationKey
                                                              ? refillRxItem.disclaimerTranslationKey
                                                              : 'pages.cart.rxItemNotCoveredWhenAddedErrorMessage'
                                                          : accountHasInsurance
                                                          ? 'pages.cart.rxItemErrorMessage'
                                                          : 'pages.cart.rxItemErrorMessageDiscount'
                                                  )
                                                : ''
                                        }
                                        image={pillImage}
                                        internalCtas={[
                                            {
                                                label: t('modals.updateCart.labels.addMore'),
                                                dataGALocation: 'AddToCartModal',
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                }
                                            },
                                            {
                                                label: t('modals.updateCart.labels.viewCart'),
                                                dataGALocation: 'AddToCartModal',
                                                variant: 'primary',
                                                onClick: () => {
                                                    dispatch(closeModal({}));
                                                    navigate('/secure/cart');
                                                }
                                            }
                                        ]}
                                    />
                                ),
                                ctas: []
                            })
                        );
                        setIsAddingToCart(false);
                    }
                })
            );
        } else {
            setIsAddingToCart(false);
        }
    };

    const handleAutoRefillToggle = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            const data: ToggleAutoFillRequest[] = rxNumbers.map((rx) => {
                return { RxNumber: rx, AutoFillToggle: autoRefillEnabled };
            });
            dispatch(
                medicineCabinetToggleAutoRefillAllRxs.trigger({
                    rxNumbers: data,
                    onSuccess: () => {
                        dispatch(closeModal({}));
                    },
                    onFailure: () => {}
                })
            );
        },
        [dispatch]
    );

    const handleShowTCModal = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            dispatch(
                openModal({
                    onClose: () => {
                        dispatch(closeModal({}));
                    },
                    ctas: [
                        {
                            label: t('modals.autoRefillTC.confirm'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
                            },
                            async: true,
                            dataGALocation: 'MedicineCabinetTCConfirm'
                        },
                        {
                            label: t('modals.autoRefillTC.cancel'),
                            variant: 'text',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'MedicineCabinetTCCancel',
                            className: 'p-4'
                        }
                    ],
                    bodyContent: <AutoRefillTermsAndConditionsModal t={t} />,
                    showClose: true
                })
            );
        },
        [dispatch, handleAutoRefillToggle, t]
    );

    const handleAutoRefillToggleAllRxs = useCallback(
        (autoRefillEnabled: boolean) => {
            if (autoRefillEnabled) {
                handleShowTCModal(autoRefillEligibleRxs, autoRefillEnabled);
            } else {
                handleAutoRefillToggle(autoRefillEligibleRxs, autoRefillEnabled);
            }
        },
        [autoRefillEligibleRxs, handleAutoRefillToggle, handleShowTCModal]
    );

    const handleShowAutoRefillToggleModal = useCallback(
        (rxNumbers: string[], autoRefillEnabled: boolean) => {
            if (autoRefillEnabled) {
                handleShowTCModal(rxNumbers, autoRefillEnabled);
            } else {
                handleAutoRefillToggle(rxNumbers, autoRefillEnabled);
            }
        },
        [handleAutoRefillToggle, handleShowTCModal]
    );

    useEffect(() => {
        if (medicineCabinetPopulated) {
            medicineCabinetFilteredRxsForStatus
                .map((rx) => rx.rxNumber)
                .forEach((rxNumber) => dispatch(medicineCabinetGetStatusForRx.trigger({ rxNumber })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [medicineCabinetPopulated]);

    return (
        <MedicineCabinetLayout>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <Row xs={1} sm={2} xl={2} className="dashboard-cta-container">
                            <Col className="mb-lg-0 pr-sm-1 mb-4 d-none d-sm-block">
                                <DashboardCtaBlock
                                    icon={'dr'}
                                    title={t('components.dashboardCtaBlock.titles.showPre')}
                                    bodyText={t('components.dashboardCtaBlock.body.showPre')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.showPre'),
                                            variant: 'dark',
                                            dataGALocation: 'MedicineCabinet',
                                            onClick: () => {
                                                navigate('/for-prescribers');
                                            }
                                        }
                                    ]}
                                />
                            </Col>
                            <Col className="mb-lg-0 mb-4 pr-lg-3">
                                <DashboardCtaBlock
                                    icon={'plane'}
                                    title={t('components.dashboardCtaBlock.titles.transferPre')}
                                    bodyText={t('components.dashboardCtaBlock.body.transferPre')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.transferPre'),
                                            variant: 'dark',
                                            dataGALocation: 'MedicineCabinet',
                                            onClick: () => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'Transfer' });
                                                navigate('/secure/prescription');
                                            }
                                        }
                                    ]}
                                />
                            </Col>
                            {/*
                             * DRX-1599: Tile to add new prescription was removed.
                             * Since I don't have sure if it's temporary, I'm commenting it out instead of removing.
                             */}
                            {/* <Col className="mb-lg-0 mb-4 pl-1 pl-lg-3">
                                <DashboardCtaBlock
                                    icon={'pill-bottle'}
                                    title={t('components.dashboardCtaBlock.titles.reqNew')}
                                    bodyText={t('components.dashboardCtaBlock.body.reqNew')}
                                    ctas={[
                                        {
                                            label: t('components.dashboardCtaBlock.ctas.reqNew'),
                                            variant: 'dark',
                                            dataGALocation: 'MedicineCabinet',
                                            onClick: () => {
                                                resetForm();
                                                setPrescriptionFlowType({ flowType: 'New' });
                                                navigate('/secure/prescription');
                                            }
                                        }
                                    ]}
                                />
                            </Col> */}
                        </Row>
                        <Row>
                            <Col className="dashboard-cta-for-prescriber mb-lg-0 mb-4 d-sm-none d-lg-none d-xl-none d-xxl-none">
                                <span className="dashboard-cta-for-prescriber-label">
                                    {t('components.dashboardCtaBlock.body.showPre')}
                                </span>
                                <button
                                    type="button"
                                    onClick={() => navigate('/for-prescribers')}
                                    className="dashboard-cta-for-prescriber-btn"
                                >
                                    <span className="mr-2">{t('components.dashboardCtaBlock.ctas.showPre')}</span>
                                    <ChevronIcon direction={'right'} />
                                </button>
                            </Col>
                        </Row>
                        <AccountBalance variant="banner" />
                    </Col>
                </Row>
            </PageSection>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                        <PrescriptionList
                            isLoading={!rxsLoaded}
                            title={t('components.prescriptionList.headerTitle')}
                            ctaText={t('components.prescriptionList.ctaText')}
                            ctaDisabled={cartItemsObject?.length === 0}
                            ctaOnClick={() => navigate('/secure/cart')}
                            emptyPrescriptionListProps={{
                                bodyLineOne: t('components.prescriptionList.emptyPrescriptionList.bodyLineOne'),
                                bodyLineTwo: t('components.prescriptionList.emptyPrescriptionList.bodyLineTwo'),
                                linkCtas: [
                                    // DRX-1599: Tile to add new prescription was removed.
                                    // Since I don't have sure if it's temporary, I'm commenting it out instead of removing.
                                    // {
                                    //     type: 'button',
                                    //     label: t('components.prescriptionList.emptyPrescriptionList.reqPre'),
                                    //     onClick: () => {
                                    //         resetForm();
                                    //         setPrescriptionFlowType({ flowType: 'New' });
                                    //         navigate('/secure/prescription');
                                    //     },
                                    //     dataGAFormName: 'EmptyMedicineCabinet'
                                    // },
                                    {
                                        type: 'button',
                                        label: t('components.prescriptionList.emptyPrescriptionList.transferPre'),
                                        onClick: () => {
                                            resetForm();
                                            setPrescriptionFlowType({ flowType: 'Transfer' });
                                            navigate('/secure/prescription');
                                        },
                                        dataGAFormName: 'EmptyMedicineCabinet'
                                    }
                                ]
                            }}
                            showToggleAutoRefillAll={displayShowToggleAutoRefillAll}
                            autoRefillToggleAllRxs={handleAutoRefillToggleAllRxs}
                        >
                            {prescriptions?.map((prescription) => (
                                <PrescriptionCard
                                    key={`medicine-cabinet-rx-card-${prescription.rxNumber}`}
                                    isAddingToCart={isAddingToCart}
                                    {...prescriptionPayloadToProps(prescription, t, accountHasInsurance, () => {
                                        handleAddToCartClick(prescription.rxNumber);
                                    })}
                                    autoRefillToggle={handleShowAutoRefillToggleModal}
                                />
                            ))}
                        </PrescriptionList>
                    </Col>
                </Row>
            </PageSection>
        </MedicineCabinetLayout>
    );
};

export default MedicineCabinet;

export const query = graphql`
    query MedicineCabinetData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
