import React, { ReactElement } from 'react';
import './BulletText.style.scss';

export interface BulletTextProps {
    className?: string;
    varient?: string;
    text: string | ReactElement;
}

export default function BulletText({ className, text, varient }: BulletTextProps) {
    return (
        <div className={`d-flex bullet-text${varient ? ' ' + varient : ''}${className ? ' ' + className : ''}`}>
            <span className="bullet-text__bullet flex-shrink-0" />
            <span className={`bullet-text__text flex-stretch`}>{text}</span>
        </div>
    );
}
