import React, { useCallback, useEffect, useState } from 'react';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import EmptyPrescriptionList, { EmptyPrescriptionListProps } from './EmptyPrescriptionList/EmptyPrescriptionList';
import './PrescriptionList.style.scss';
import LoadingMessage from 'ui-kit/loading-message/loading-message';
import { Switch } from 'ui-kit/switch';
import { useDispatch, useSelector } from 'react-redux';
import {
    medicineCabinetAutoRefillToggleAllSelector,
    medicineCabinetAutoRefillToggleBusySelector,
    medicineCabinetAutoRefillToggleDisabledSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';
import InfoIcon from 'ui-kit/icons/info/circle-info-icon';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';

export interface PrescriptionListProps {
    isLoading: boolean;
    title: string;
    ctaText?: string;
    ctaDisabled?: boolean;
    ctaOnClick?: () => void;
    children?: React.ReactNode | React.ReactNode[];
    emptyPrescriptionListProps: EmptyPrescriptionListProps;
    showToggleAutoRefillAll: boolean;
    autoRefillToggleAllRxs: (checked: boolean) => void;
}

function FormatPrescriptionListCards({ children }: { children: React.ReactNode | React.ReactNode[] }) {
    if (children === undefined || children === null) {
        return null;
    }
    if (!Array.isArray(children)) {
        return (
            <Row>
                <Col xs={4}>{children}</Col>
            </Row>
        );
    } else {
        return (
            <Row xs={1} md={3} lg={3} xl={3} className="d-flex">
                {children.map((_card, index: number) => (
                    <Col className="mb-4 flex-fill" key={`rx-${index}`}>
                        {_card}
                    </Col>
                ))}
            </Row>
        );
    }
}

export default function PrescriptionList({
    isLoading,
    ctaText,
    ctaDisabled,
    ctaOnClick,
    title,
    children,
    emptyPrescriptionListProps,
    showToggleAutoRefillAll,
    autoRefillToggleAllRxs
}: PrescriptionListProps) {
    const { t } = useTranslation();
    const [prescriptionsList, setPrescriptionsList] = useState([]);
    const autoRefillToggleAllEnabled = useSelector(medicineCabinetAutoRefillToggleAllSelector);
    const autoRefillToggleBusy = useSelector(medicineCabinetAutoRefillToggleBusySelector);
    const autoRefillToggleDisabled = useSelector(medicineCabinetAutoRefillToggleDisabledSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        children?.sort((a: any, b: any) => {
            // Pending items should be sorted according to whether or not an Rx
            // number exists.
            if (a.props.orderStatus === b.props.orderStatus && a.props.orderStatus === 'PENDING') {
                // Transfers have a value of 1 and requests have a value of 0.
                const orderA = a.props.rxNumber && a.props.rxNumber !== 'N/A' ? 1 : 0;
                const orderB = b.props.rxNumber && b.props.rxNumber !== 'N/A' ? 1 : 0;

                // Sort in ascending order so that requests come first.
                return orderA - orderB;
            }

            // For items with the same sort order, sort them in descending order
            // by rxSeqNum.
            if (a.props.sortOrder === b.props.sortOrder) {
                return b.props.rxSeqNum - a.props.rxSeqNum;
            }

            return a.props.sortOrder - b.props.sortOrder;
        });
        setPrescriptionsList(children);
    }, [children]);

    const handleToggleAllRxs = useCallback(
        (checked: boolean) => {
            return autoRefillToggleAllRxs(checked);
        },
        [autoRefillToggleAllRxs]
    );

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    return (
        <div className="prescription-list">
            <Row className="prescription-list__header">
                <Col>
                    <h2>{title}</h2>
                    <div className="spacer" />
                </Col>
                {ctaText && (
                    <Col xs={5} className="d-flex justify-content-end align-items-start">
                        <Button
                            className="prescription-list-button"
                            label={ctaText}
                            onClick={ctaOnClick}
                            type="button"
                            dataGALocation="MedicineCabinet"
                            disabled={ctaDisabled}
                        />
                    </Col>
                )}
            </Row>
            <Row>
                <Col className="d-flex flex-direction-row mb-4 refill-rx-label">
                    <span className="text-dark">{t('pages.medicineCabinet.refillPrescriptionsLabel')}</span>
                </Col>
            </Row>
            <LoadingMessage
                isVisible={isLoading && !(Array.isArray(children) && children.length > 0)}
                text={t('pages.medicineCabinet.loading')}
            />
            {!isLoading && ENABLE_AUTO_REFILL && showToggleAutoRefillAll && (
                <>
                    <Row>
                        <Col className="auto-refill-learn-more">
                            <div className="auto-refill-learn-more-info-icon">
                                <InfoIcon />
                            </div>
                            <div className="auto-refill-learn-more-text">
                                <h3 className="text-dark">{t('pages.medicineCabinet.autoRefillLearnTitle')}</h3>
                                <h4 className="text-dark">{t('pages.medicineCabinet.autoRefillLearnBody')} </h4>
                                <Button
                                    onClick={handleAutoRefillModal}
                                    variant="text"
                                    label={t('pages.medicineCabinet.autoRefillLearnMoreLink')}
                                    type="button"
                                />
                                .
                            </div>
                        </Col>
                    </Row>
                    {/*
                     * DRX-1575: Added 'false' to avoid showing the auto refill toggle for all cards in the list.
                     * It is temporary change until it be fixed on the back-end.
                     */}
                    {false && (
                        <Row>
                            <Col className="d-flex flex-direction-row mb-4 auto-refill-toggle-all-container">
                                <span className="text-dark">{t('components.autoRefillToggle.toggleAllLabel')}</span>
                                <Switch
                                    checked={autoRefillToggleDisabled ? false : autoRefillToggleAllEnabled}
                                    onChange={(val: boolean) => handleToggleAllRxs(val)}
                                    disabled={autoRefillToggleDisabled || autoRefillToggleBusy}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}

            {!isLoading && (children === undefined || (Array.isArray(children) && children.length <= 0)) ? (
                <EmptyPrescriptionList {...emptyPrescriptionListProps} />
            ) : (
                <FormatPrescriptionListCards>{prescriptionsList}</FormatPrescriptionListCards>
            )}
        </div>
    );
}
