import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import withSessionExpiration from 'hoc/withSessionExpiration';

import PageLayout from 'components/layouts/page/page.layout';

import {
    MedicineCabinetLayoutProps,
    MedicineCabinetLayoutHeaderProps
} from 'components/layouts/medicine-cabinet/medicine-cabinet.props';

import './medicine-cabinet.style.scss';

const MedicineCabinetHeader = ({ image, text }: MedicineCabinetLayoutHeaderProps) => {
    return (
        <div className="medicine-cabinet-layout-header">
            <Container fluid="xl">
                <Row>
                    <Col
                        xs={12}
                        md={{ span: 7, offset: 1 }}
                        className="d-flex align-items-center justify-content-center justify-content-md-start"
                    >
                        {text && <h1>{text}</h1>}
                    </Col>
                    <Col xs={12} md={4} className="d-flex align-self-end justify-content-center">
                        {image && (
                            <GatsbyImage
                                image={getImage(image)}
                                alt={''}
                                className="medicine-cabinet-layout-header-image"
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const MedicineCabinetLayout = ({ children, greetingMessage }: MedicineCabinetLayoutProps) => {
    const { t } = useTranslation();

    const imageData = useStaticQuery(graphql`
        query {
            mcBird: file(relativePath: { eq: "assets/images/medicine-cabinet-bird.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(formats: [AUTO, WEBP], height: 200)
                }
            }
        }
    `);

    return (
        <PageLayout
            pageContentClassName="medicine-cabinet-layout-wrapper"
            metaData={{ nodeTitle: t('pages.medicineCabinet.eyebrowText') }}
        >
            <div className="medicine-cabinet-layout">
                <MedicineCabinetHeader
                    image={imageData.mcBird}
                    text={greetingMessage ?? t('pages.medicineCabinet.greetingMessageDefault')}
                />
                <div className="medicine-cabinet-content">{children}</div>
            </div>
        </PageLayout>
    );
};

export default withSessionExpiration(MedicineCabinetLayout);
