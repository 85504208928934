import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container } from 'react-bootstrap';
import PrescriptionCardModal from 'components/prescriptions-list-v2/prescription-card-modal/prescription-card-modal';
import Button from 'ui-kit/button/button';
import PrescriptionCardIcon from './PrescriptionCard.icon';
import { drugSelector } from 'state/drug/drug.selectors';
import { accountProfileSelector, accountHasInsuranceSelector } from 'state/account/account.selectors';
import { openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { formatPrice } from 'schema/price.schema';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import './PrescriptionCard.style.scss';
import BirdiPrice from 'components/birdi-price/birdi-price.component';
import { TrackViewItem, ViewItemType } from 'util/google_optimize/optimize_helper';
import { PrescriptionCardProps } from './types';
import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';
import { AutoRefillToggle } from 'components/auto-refill-toggle';
import { medicineCabinetAutoRefillToggleBusySelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { addDays, isRxWhiteCard, isShipStatusExpired } from 'util/prescription';
import Spinner from 'ui-kit/spinner/spinner';
import { useRxSubStatus } from 'hooks/useRxSubStatus';
import { CSSTransition } from 'react-transition-group';
import { PrescriptionStatuses } from 'components/prescription-statuses';
import { PrescriptionDetails } from 'components/prescription-details';

function showDebuginfo(card: PrescriptionObjectPayload) {
    console.group(`${card.dispensedProductName}`);
    console.log('Prescription Card: ' + card.dispensedProductName);
    console.log('webEligibilityStatus: ' + card.webEligibilityStatus);
    console.log('inOrderCart: ' + card.inOrderCart);
    console.log('rxStatus: ' + card.rxStatus);
    console.log('itemInWorkflow: ' + card.itemInWorkflow);
    console.log('orderLineQueueStatus: ' + card.orderLineQueueStatus);
    console.log('nextFillDate: ' + card.nextFillDate);
    console.log('lastFillDate: ' + card.lastFillDate);
    console.log('shipStatusExpirationDate: ' + addDays(card.lastFillDate, 14).toLocaleDateString('en-US'));
    console.log('isShipStatusExpired: ' + isShipStatusExpired(card.lastFillDate));
    console.log(card);
    console.groupEnd();
}

function findPriceMatch(rxNumber: string, drugDiscountPrices: { price: string; rxNumber: string }[]) {
    // Check if the price exists for the given prescription number.
    let priceMatches = drugDiscountPrices.filter((item) => {
        return item.rxNumber === rxNumber;
    });

    if (priceMatches.length > 0) {
        return priceMatches[0].price;
    }

    return '';
}

export default function PrescriptionCard({
    fullPayload,
    prescriptionName,
    orderStatus,
    refillsLeft,
    rxNumber,
    details = [],
    statuses = [],
    ctas = [],
    isAddingToCart,
    autoRefillToggle,
    footNote
}: PrescriptionCardProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const expanded = false;
    const [isRxBusy, setIsRxBusy] = useState(false);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const profileObject = useSelector(accountProfileSelector);
    const price = findPriceMatch(rxNumber, drugDiscountPrices);

    const { getRxSubStatus, isOrderProcessing } = useRxSubStatus(fullPayload);

    const rxDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'RX';
        });
    }, [statuses]);

    const orderDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'ORDER';
        });
    }, [statuses]);

    const rxNextRefillStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'NEXT_REFILL';
        });
    }, [statuses]);

    const rxAutoRefillEnabled = useMemo(() => {
        return fullPayload.autoRefillEnabled;
    }, [fullPayload.autoRefillEnabled]);

    const handleAutoRefillInCard = useCallback(
        (value: boolean) => {
            if (autoRefillToggle) {
                autoRefillToggle([rxNumber], value);
            }
        },
        [autoRefillToggle, rxNumber]
    );
    const autoRefillToggleBusy = useSelector(medicineCabinetAutoRefillToggleBusySelector);

    const orderSubStatus = useMemo(() => {
        return getRxSubStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullPayload.rxSubStatus]);

    const orderSubStatusLoading = useMemo(() => {
        return fullPayload.rxSubStatus?.loading;
    }, [fullPayload.rxSubStatus]);

    const rxCardFootNote = useMemo(() => {
        if (footNote) {
            return footNote;
        } else if (orderSubStatus.subStatus && orderSubStatus.footNote) {
            return orderSubStatus.footNote;
        }
        return '';
    }, [footNote, orderSubStatus.footNote, orderSubStatus.subStatus]);

    const handleInfoClick = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: `prescription-card-modal prescription-card-modal-status-${(orderStatus || 'unknown')
                    .toLowerCase()
                    .replace(/ /g, '_')}`,
                bodyContent: (
                    <PrescriptionCardModal
                        fullPayload={fullPayload}
                        prescriptionName={prescriptionName}
                        orderStatus={orderStatus}
                        orderSubStatus={orderSubStatus.orderStatus}
                        refillsLeft={refillsLeft}
                        rxNumber={rxNumber}
                        rxDisplayStatuses={rxDisplayStatuses}
                        orderDisplayStatuses={orderDisplayStatuses}
                        ctas={ctas}
                        setIsRxBusy={setIsRxBusy}
                    />
                ),
                size: 'lg',
                ctas: []
            })
        );

        let priceValue: number = 0;

        if (!accountHasInsurance && price && price !== 'NA') {
            priceValue = Number(formatPrice(price).replace('$', ''));
        }

        const item: ViewItemType = {
            rxNumber: rxNumber,
            name: prescriptionName,
            variant: fullPayload.fillQuantity,
            price: priceValue
        };

        TrackViewItem(item);
    }, [
        accountHasInsurance,
        ctas,
        dispatch,
        fullPayload,
        orderDisplayStatuses,
        orderStatus,
        prescriptionName,
        price,
        refillsLeft,
        rxDisplayStatuses,
        rxNumber
    ]);

    const nodeRef = useRef(null);

    return (
        <Container
            fluid
            className={`prescription-card h-100 ${expanded ? 'expanded' : ''} ${orderStatus
                .toLowerCase()
                .replace(/ /g, '_')}`}
        >
            {/* Icon & Auto-refill */}
            <div className="prescription-card__icon_container">
                <CSSTransition
                    nodeRef={nodeRef}
                    in={!!orderSubStatus.orderStatus}
                    timeout={4000}
                    classNames="prescription-card-icon"
                >
                    <div
                        ref={nodeRef}
                        className={'prescription-card__icon-badge-new'}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => {
                            showDebuginfo(fullPayload);
                        }}
                    >
                        <PrescriptionCardIcon variant={orderStatus} orderSubStatus={orderSubStatus.orderStatus} t={t} />
                    </div>
                </CSSTransition>
                {ENABLE_AUTO_REFILL &&
                    fullPayload.autorefillPlanEligible &&
                    fullPayload.autoRefillEligible !== null && (
                        <AutoRefillToggle
                            checked={rxAutoRefillEnabled}
                            onChange={(val: boolean) => handleAutoRefillInCard(val)}
                            t={t}
                            rxEligible={fullPayload.autoRefillEligible}
                            isBusy={autoRefillToggleBusy}
                        />
                    )}
            </div>

            <div className="prescription-card__content-container">
                <div className="prescription-card__content-details">
                    <div className="prescription-card__subtitle">
                        {t('components.prescriptionCard.rxNumberHeaderText', { number: rxNumber })}
                    </div>
                    <div className="prescription-card__title-info-container">
                        <h4 className="prescription-card__title" title={prescriptionName?.toUpperCase()}>
                            {prescriptionName}
                        </h4>
                        <button className="prescription-card__info" onClick={handleInfoClick}>
                            <CircleInfo alt="Click for more" />
                        </button>
                    </div>

                    <div className="prescription-card__rx-details-container">
                        <PrescriptionDetails details={details} expanded={expanded} />
                    </div>

                    <div className="prescription-card__rx-order-statuses">
                        <div className="prescription-card__rx-statuses-container">
                            <div className="prescription-card__rx-statuses">
                                <div className="prescription-card__details-status-headings">
                                    {t('components.prescriptionCard.statusTitle')}
                                </div>
                                <PrescriptionStatuses statuses={rxDisplayStatuses} expanded={expanded} />
                                <div className="prescription-card__order-statuses">
                                    {orderDisplayStatuses.length > 0 && (
                                        <>
                                            {orderSubStatusLoading ? (
                                                <>
                                                    <span className="prescription-card__status">
                                                        {t('components.prescriptionCard.fetchingOrderStatus')}
                                                    </span>
                                                    <Spinner
                                                        isVisible
                                                        t={t}
                                                        fullOverlay={false}
                                                        isSmall
                                                        className="prescription-card__loading-spinner"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {orderSubStatus.orderStatus !== '' && (
                                                        <>
                                                            <div className="prescription-card__details-status-headings">
                                                                {t('components.prescriptionCard.orderStatusText')}
                                                            </div>
                                                            <ul className="prescription-card__status-list prescription-card__order-status-list">
                                                                <li className="prescription-card__status">
                                                                    {orderSubStatus.orderStatus}
                                                                </li>
                                                                {orderSubStatus.subStatus && (
                                                                    <li
                                                                        className={`prescription-card__order-sub-status ${
                                                                            isOrderProcessing ? 'text-danger' : ''
                                                                        }`}
                                                                    >
                                                                        {orderSubStatus.subStatus}
                                                                    </li>
                                                                )}
                                                            </ul>
                                                            <div className="prescription-card__show-track-full-status">
                                                                <Button
                                                                    type="button"
                                                                    variant={'text'}
                                                                    label={t(
                                                                        'components.prescriptionCard.trackFullStatus'
                                                                    )}
                                                                    onClick={handleInfoClick}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="prescription-card__rx-refills-container">
                                {!isRxWhiteCard(orderStatus) && (
                                    <div className="prescription-card__refills">
                                        <span className="prescription-card__refills-title">
                                            {t('components.prescriptionCard.refillsLeftTitle')}
                                        </span>
                                        <br />
                                        <span className="prescription-card__refills-left">{refillsLeft}</span>
                                    </div>
                                )}
                                <div>
                                    {rxNextRefillStatuses.length > 0 && (
                                        <>
                                            <div className="prescription-card__details-status-headings">
                                                {t('components.prescriptionCard.orderNextRefill')}
                                            </div>
                                            <PrescriptionStatuses statuses={rxNextRefillStatuses} expanded={expanded} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="prescription-card__content-ctas">
                    {rxCardFootNote && (
                        <div className="prescription-card__rx-expired-note">
                            <span className="text-dark">{rxCardFootNote}</span>
                        </div>
                    )}

                    {profileObject && !accountHasInsurance && (
                        <Col
                            className={`prescription-card__rx-expired-note prescription-card__birdi-price ${
                                expanded ? 'is-primary' : ''
                            }`}
                        >
                            <BirdiPrice accountHasInsurance={accountHasInsurance} />
                            <br />
                            {price && price !== 'NA' && <span>{formatPrice(price)}</span>}
                            {/* If the price was not returned successfuly by the API, then it will just be an empty string, in which case we display an N/A message. */}
                            {price && price === 'NA' && <small>{t('components.prescriptionCard.priceNA')}</small>}
                            {!price && <small>{t('components.prescriptionCard.fetchingPrice')}</small>}
                        </Col>
                    )}

                    {ctas.map((cta, idx) => (
                        <Button
                            key={`prescription-card-cta-button-${idx}`}
                            {...cta}
                            type={'button'}
                            async
                            onClick={() => {
                                setIsRxBusy(true);
                                cta.onClick();
                            }}
                            isBusy={isRxBusy && isAddingToCart}
                            disabled={isAddingToCart || cta.disabled}
                            dataGALocation="MedicineCabinet"
                            variant={cta.disabled ? 'outline-smoke' : 'outline-primary'}
                        />
                    ))}
                </div>
            </div>
        </Container>
    );
}
